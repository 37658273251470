<template>
  <div class="page-content" v-if="user">
    <page-breadcrumb title="User Detail" :items="breadcrumbItems" />
    <b-card class="mt-3">
      <!-- edit buttons -->
      <div class="d-flex justify-content-end">
        <!-- <b-button class="btn-icon" @click="editAble">
          <feather-icon :icon="!isEditing ? 'EditIcon' : 'XIcon'" />
        </b-button> -->
        <!-- <b-button v-if="isEditing" class="btn-icon ml-50" variant="primary" @click="updateUserEmail">
          <b-spinner v-if="isLoading" small class="mr-50" />
          <feather-icon v-else icon="CheckIcon"/>
        </b-button> -->
        <delete-button v-if="userData.role === 'admin'" class="btn-icon ml-50" variant="relief-danger"
          @delete="suspendUser" v-b-tooltip.hover="'suspend user'">
          <b-spinner v-if="isLoading" small />
          <feather-icon v-else icon="SlashIcon" />
        </delete-button>
      </div>
      <!-- edit buttons end -->

      <b-media class="mt-1">
        <template #aside>
          <b-avatar rounded size="60" :src="user.avatar" variant="light-primary" badge class="badge-minimal"
            badge-variant="success">
            <feather-icon v-if="!user.email" icon="UserIcon" size="60" />
          </b-avatar>
        </template>
        <b-card-text class="d-flex">
          <div class="mr-3">
            <template>
              <b>Email: {{ user.email }}</b>
            </template>
            <!-- <validation-observer
              ref="updateEmailForm"
              v-slot="{ handleSubmit }"
            >
              <b-form @submit.stop.prevent="handleSubmit()">
                <validation-provider
                  name="email"
                  v-slot="validationContext"
                  :rules="{ required: true, email: true }"
                >
                  <b-form-input v-model="user.email" name="email" id="email" type="text" :state="getValidationState(validationContext)" />
                  <b-form-invalid-feedback id="milestone-id-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form>
            </validation-observer> -->
            <span class="mt-1" style="display: block">Name: {{ user.name }}</span>
          </div>
          <div class="d-flex flex-column">

            <b-popover v-if="resetCondition.message" target="popover-target-email" triggers="hover" placement="top">
              {{ resetCondition.message }}
            </b-popover>
            <b-popover v-if="resetCondition.message" target="popover-target-password" triggers="hover" placement="top">
              {{ resetCondition.message }}
            </b-popover>
            
          <b-popover v-if="resetCondition.message" target="popover-target-email" triggers="hover" placement="top">
            {{ resetCondition.message }}
          </b-popover>
          <b-popover v-if="resetCondition.message" target="popover-target-password" triggers="hover" placement="top">
            {{ resetCondition.message }}
          </b-popover>
            <div id="popover-target-email" class="mb-1">
              <b-button v-if="!isEdittingEmail" size="sm" variant="success" :disabled="!resetCondition.is_allow"
                @click="isEdittingEmail = true">Reset Email</b-button>
              <div v-else class="d-flex align-items-center">
                <span class="mr-1" style="text-wrap: nowrap">New Email</span>
                <validation-observer ref="updateEmailForm" v-slot="{ handleSubmit }" class="mr-1">
                  <b-form @submit.stop.prevent="handleSubmit()">
                    <validation-provider name="email" v-slot="validationContext"
                      :rules="{ required: true, email: true }">
                      <b-form-input v-model="userEmail" name="email" id="email" type="text"
                        :state="getValidationState(validationContext)" />
                      <b-form-invalid-feedback id="milestone-id-feedback">{{ validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                    </validation-provider>
                  </b-form>
                </validation-observer>
                <b-button class="mr-1" variant="success" @click="updateUserEmail">Save</b-button>
                <b-button class="mr-1" variant="success" @click="isEdittingEmail = false">Cancel</b-button>
              </div>
            </div>
            <div id="popover-target-password">
              <b-button v-if="!isEdittingPassword" variant="success" size="sm" :disabled="!resetCondition.is_allow"
                @click="isEdittingPassword = true">Reset Password</b-button>
              <div v-else class="d-flex align-items-center">
                <span class="mr-1" style="text-wrap: nowrap">New Password</span>
                <validation-observer ref="updatePasswordForm" v-slot="{ handleSubmit }" class="mr-1">
                  <b-form @submit.stop.prevent="handleSubmit()">
                    <validation-provider name="password" v-slot="validationContext" :rules="{ required: true }">
                      <b-form-input v-model="userPassword" name="password" id="password" type="text"
                        :state="getValidationState(validationContext)" />
                      <b-form-invalid-feedback id="milestone-id-feedback">{{ validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                    </validation-provider>
                  </b-form>
                </validation-observer>
                <b-button class="mr-1" variant="success" @click="updateUserPassword">Save</b-button>
                <b-button class="mr-1" variant="success" @click="isEdittingPassword = false">Cancel</b-button>
              </div>
            </div>
            <div class="mt-1">
              <b-button variant="danger" size="sm" :disabled="deleteConditionCheck" @click="deleteUser">
                Delete Account
              </b-button>
            </div>
          </div>
        </b-card-text>
      </b-media>
      <b-row>
        <b-col cols="6">
          <h5 class="mb-75 mt-2">Status: <b-badge :variant="userStatus.variant">{{ userStatus.text }}</b-badge></h5>
          <div class="d-flex align-items-center">
            <div v-if="!isEdittingLanguage">
              <h5 class="mb-75 mt-2">Language: {{ user.language }}</h5>
              <h5 class="mb-75 mt-2">Country: {{ user.country }}</h5>
            </div>
            <div v-else>
              <div class="d-flex align-items-center">
                <h5 class="mb-75 mt-2 mr-1">Language: </h5>
                <form-generator :model="user"
                  :schema="[{ cols: 12, fields: [{ label: '', field: 'language', input_type: 'language', required: true }] }]" />
              </div>
              <div class="d-flex align-items-center">
                <h5 class="mb-75 mt-2 mr-1">Country: </h5>
                <form-generator :model="user" style="width: 130px"
                  :schema="[{ cols: 12, fields: [{ label: '', field: 'country', input_type: 'select', options: COUNTRY_OPTIONS, required: true, allow_empty: false }] }]" />
              </div>
            </div>
            <b-button v-if="user_products && !user_products.length && !isEdittingLanguage" class="ml-2" size="sm"
              variant="success" :disabled="user_products.length !== 0" @click="isEdittingLanguage = true">Update
              Language</b-button>
            <b-button v-if="user_products && !user_products.length && isEdittingLanguage" class="ml-2" size="sm"
              variant="success" @click="updateLanguage">Confirm Update</b-button>
          </div>


          <h5 class="mb-75 mt-2">Phone: {{ user.phone }}</h5>
          <h5 class="mb-75 mt-2">Verified Email: {{ user.is_email_verified ? 'Yes' : 'No' }}</h5>
        </b-col>
        <b-col cols="6">
          <h5 class="mb-75 mt-2">Username: {{ user.username }}</h5>
          <h5 class="mb-75 mt-2">DOB: {{ user.date_of_birth }}</h5>
          <h5 class="mb-75 mt-2">Sex: {{ sex_options[user.sex] }}</h5>
          <h5 class="mb-75 mt-2">Job: {{ job_options[user.job] }}</h5>
        </b-col>
      </b-row>

      <!-- login devices -->
      <div class="row">
        <div class="col-8">
          <div class="mt-2">
            <h5 class="mb-75">Device:</h5>
          </div>
        </div>
        <div class="col-2">
          <div class="mt-2">
            <h5 class="mb-75">First login at</h5>
          </div>
        </div>
        <div class="col-2">
          <div class="mt-2">
            <h5 class="mb-75">Login device</h5>
          </div>
        </div>
      </div>
      <div class="row" v-for="device in login_devices" :key="device._id">
        <div class="col-8">
          <div class="mt-1">
            {{ getLoginDevice(device) }}
          </div>
        </div>
        <div class="col-2">
          <div class="mt-1">
            {{ device ? device.createdAt : '' | local_time_string }}
          </div>
        </div>
        <div class="col-2">
          <div class="mt-1">
            {{ device.registration_id ? 'Active' : 'Inactive' }}
          </div>
        </div>
      </div>
      <!-- login devices end -->
    </b-card>
    <div class="mt-1" v-if="lastAct">
      <h5 class="text-capitalize mb-75">Ngày thực hiện last act: {{ $options.filters.local_time_string(lastAct.date) }}
      </h5>
      <h5 class="text-capitalize mb-75">Thời gian thực hiện last act: {{ lastAct.learn_time }}</h5>
    </div>
    <section class="my-3">
      <h2 class="h2">Product</h2>
      <b-card>
        <user-product-table v-if="user_products" :user-products="user_products" />
      </b-card>
    </section>
    <section class="my-3">
      <h2 class="h2">Native 1-1</h2>
      <b-card>
        <data-table-ssr id="group_list" ref="group_list" :columns="native11_product_fields"
          :get-list-fn="getUserNative11Products" :pagination="false">
          <template #slot_status="{ slotData }">
            <span v-if="slotData.status == 0" style="color: #ff4040">DEACTIVATED</span>
            <span v-if="slotData.status == 1" style="color: #1f9455">ACTIVE</span>
          </template>
        </data-table-ssr>
      </b-card>
    </section>
    <section>
      <loading-button variant="success" class="mb-1" icon="RefreshCcwIcon" :is-loading="is_recalculating"
        @click="isShowEntryLink = !isShowEntryLink">View Entry Test Report</loading-button>
      <div>
        <a v-if="isShowEntryLink" :href="entryTestWebLink" target="_blank">{{ entryTestWebLink }}</a>
      </div>
    </section>
    <section class="mt-2">
      <loading-button variant="success" class="mb-1" icon="RefreshCcwIcon" :is-loading="is_recalculating"
        @click="isShowSpeakingTest = !isShowSpeakingTest">View Speaking Test Report</loading-button>
      <data-table-ssr v-show="isShowSpeakingTest" id="speaking_test" ref="speaking_test" :columns="speaking_test_schema"
        :get-list-fn="getSpeakingTest" :pagination="false">
        <template #slot_link="{ slotData }">
          <a :href="slotData.link" target="_blank">{{slotData.link}}</a>
        </template>
      </data-table-ssr>
    </section>
    <user-learn-progress-table v-if="learnProgress" :data="learnProgress" />
    <action-buttons back-button />
    <b-card v-if="user.role === 2">
      <h2 class="h2">Teacher Info</h2>
      <div>
        <b-form-group label="About me" label-for="about_me">
          <b-form-input v-model="user.teacher_description.about_me" id="about_me" />
        </b-form-group>
        <b-form-group label="Education" label-for="education">
          <b-form-input v-model="user.teacher_description.education" id="education" />
        </b-form-group>
        <b-form-group label="Experience" label-for="experience">
          <b-form-input v-model="user.teacher_description.experience" id="experience" />
        </b-form-group>
        <b-button variant="success" style="float: right" @click="updateTeacherDescription">Update</b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import service from "../service";
import UserProductTable from "./_components/UserProductTable.vue";
import UserRecordTable from './_components/UserRecordTable.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import { STATUS } from './../constants.js';
import UserLearnProgressTable from './_components/UserLearnProgressTable.vue';
const COUNTRY_OPTIONS = [
  { text: 'en', value: 'en' },
  { text: 'vn', value: 'vn' },
  { text: 'th', value: 'th' },
  { text: 'id', value: 'id' },
  { text: 'kh', value: 'kh' },
  { text: 'la', value: 'la' },
  { text: 'jp', value: 'jp' },
  { text: 'mm', value: 'mm' },
];
const native11_product_fields = [
  { label: 'STT', field: 'stt', input_type: 'table-order' },
  { label: 'Active Code', field: 'active_code' },
  { label: 'Status', field: 'status', input_type: 'slot' },
  { label: 'Booked Class', field: 'booked_class', value_handler: (model) => { return model.amount_total - model.amount_remain } },
  { label: 'Left Booking', field: 'amount_remain' },
  { label: 'Total Booking', field: 'amount_total' },
  { label: 'Expired Date', field: 'expired_date', input_type: 'datetime' },
  { label: 'Active At', field: 'createdAt', input_type: 'datetime' },
];
const speaking_test_schema = [
  { label: 'Ngày Test', field: 'createdAt', input_type: 'datetime' },
  { label: 'Link Report', field: 'link', input_type: 'slot' },
];
export default {
  components: {
    UserProductTable,
    UserRecordTable,
    ValidationProvider,
    ValidationObserver,
    UserLearnProgressTable
  },
  data() {
    return {
      user: null,
      user_products: null,
      login_devices: null,
      isUpdating: false,
      isDeleting: false,
      user_learn_record: null,
      is_recalculating: false,
      isEditing: false,
      isLoading: false,
      isShowEntryLink: false,
      entryTestWebLink: null,
      learnProgress: null,
      userPronunResult: null,
      userBreakingResult: null,
      lastAct: null,
      sex_options: {
        1: "Male",
        2: "Female",
        3: "Other"
      },
      job_options: {
        1: "Pupil",
        2: "Student",
        3: "Self-employed",
        4: "Office worker",
        5: "Retiree",
        6: "Other"
      },
      resetCondition: {
        is_allow: false,
        message: null
      },
      userEmail: null,
      userPassword: null,
      isEdittingEmail: false,
      isEdittingPassword: false,
      isEdittingLanguage: false,
      COUNTRY_OPTIONS,
      native11_product_fields,
      speakingTest: null,
      isShowSpeakingTest: false,
      speaking_test_schema
    };
  },
  computed: {
    userId() {
      return this.$route.params.user_id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: "User List",
          to: { name: "user-list" },
        },
      ];
      items.push({ text: "User Detail", active: true });
      return items;
    },
    userStatus() {
      let status = STATUS.find(item => item.value === this.user.status);
      return status || { text: 'unknown' };
    },
    userData() {
      return this.$store.getters['auth/userData']
    },
    deleteConditionCheck() {
      if (this.user_products.length || this.user.status === 2) {
        return true
      }
      return false
    }
  },
  async created() {
    this.get();
    this.getUserLearnProgress();
    this.getUserLastAct();
    this.resetEmailCondition();
  },
  methods: {
    async get() {
      let response = await service.get({ id: this.userId });
      if (response) {
        this.user = response.user;
        this.user_products = response.user_products;
        this.login_devices = response.login_devices;
        this.entryTestWebLink = `https://goga.ai/${this.user.language}/entry-test-report?email=${this.user.email}`;
        this.userEmail = this.user.email;
      }
    },
    async getRecord() {
      this.is_recalculating = true;
      let response = await service.getUserRecord({ user_id: this.userId });
      if (response) {
        this.user_learn_record = response.user_summary
      }
      this.is_recalculating = false;
    },

    editAble() {
      this.isEditing = !this.isEditing
    },

    async updateUserEmail() {
      let validation = await this.$refs.updateEmailForm.validate();
      if (validation) {
        this.isLoading = true
        const response = await service.updateUserEmail({ id: this.userId, email: this.userEmail, current_email: this.user.email })
        if (response) {
          this.$store.dispatch('pushSuccessNotify', { text: 'Successfully updated email!' })
          this.get();
          this.isEdittingEmail = false;
          this.userEmail = null;
        }
        this.isLoading = false
      }
    },

    async suspendUser() {
      this.isLoading = true
      const response = await service.suspend({ id: this.userId })
      if (response) {
        this.get()
      }
      this.isLoading = false
    },
    async deleteUser() {
      this.isLoading = true
      const response = await service.deleteUser({ id: this.userId })
      if (response) {
        this.get()
      }
      this.isLoading = false
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    getLoginDevice(device) {
      if (!device || !device.info) {
        return ''
      }
      if (device.info[0] !== '{') {
        return device.info
      }
      let split = device.info.split("brand':'")[1]
      split = split.slice(0, split.length - 2).replace("','os':'", ', ')

      return split
    },
    async getUserLearnProgress() {
      let response = await service.getUserLearnProgress({ user_id: this.userId });
      if (response) {
        this.learnProgress = response;
      }
    },
    async getUserLastAct() {
      this.lastAct = await service.getUserLastAct({ id: this.userId });
    },
    async resetEmailCondition() {
      let response = await service.resetEmailCondition({ id: this.userId });
      if (response) {
        this.resetCondition.is_allow = response.is_allow;
        this.resetCondition.message = response.message;
      }
    },
    async updateUserPassword() {
      let validation = await this.$refs.updatePasswordForm.validate();
      if (validation) {
        this.isLoading = true
        const response = await service.updateUserPassword({ email: this.userEmail, new_password: this.userPassword })
        if (response) {
          this.$store.dispatch('pushSuccessNotify', { text: 'Successfully updated password!' })
          this.get();
          this.isEdittingPassword = false;
          this.userPassword = null;
        }
        this.isLoading = false;
      }
    },
    async updateTeacherDescription() {
      const response = await service.updateTeacherDescription({ teacher_id: this.user._id, teacher_description: this.user.teacher_description })
      if (response.type === 'SUCCESS') {
        this.$store.dispatch('pushSuccessNotify', { text: 'Successfully updated!' })
      }
      else {
        this.$store.dispatch('pushErrorNotify', { text: 'Error!' })
      }
    },
    async updateLanguage() {
      this.isLoading = true;
      const response = await service.updateLanguage({ user_id: this.userId, new_language: this.user.language, new_country: this.user.country });
      if (response) {
        this.isEdittingLanguage = false;
      }
      this.isLoading = false;
    },

    async getUserNative11Products() {
      let response = await service.getUserNative11Products({ user_id: this.userId });
      if (response) {
        let { list, total } = response;
        return { list, total }
      }
      return { list: [], total: 0 }
    },

    async getSpeakingTest() {
      let response = await service.getSpeakingTest({ user_id: this.userId });
      if (response) {
        let { list, total } = response;
        return { list, total }
      }
      return { list: [], total: 0 }
    },

    speakingTestLink(id){

    }
  },
};
</script>
