module.exports = {
  STATUS: [
    { text: 'ACTIVATED', value: 1, variant: "success" },
    { text: 'DELETED', value: 2, variant: "secondary" },
    { text: 'SUSPENDED', value: 3, variant: "danger" },
  ],

  USER_LEVELS_CONFIG: {
    key: 'USER_LEVELS',
    default_data: [
    ]
  },

  MODULE_CATEGORY: 'user',
}
