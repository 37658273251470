<template>
  <div>
    <b-table :items="userProducts" :fields="fields" responsive="sm" small>
      <template #cell(stt)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(expired_date)="data">
        {{ data.value | local_time_string }}
      </template>
      <template #cell(status)="data">
        <span :class="getStatus(data) ? 'c-green' : 'c-red'">{{ STATUS[getStatus(data)] }}</span>
      </template>
      <template #cell(createdAt)="data">
        {{ data.value | local_time_string }}
      </template>
      <template #cell()="data">
        {{ data.value }}
      </template>
    </b-table>
  </div>
</template>
<script>
const STATUS = ['DEACTIVATED', 'ACTIVE']
export default {
  props: {
    userProducts: { type: Array, required: true },
  },
  data() {
    return {
      fields: [
        "stt",
        "product_key",
        "status",
        "is_unlimited",
        "language",
        "expired_date",
        "createdAt",
      ],
      STATUS
    };
  },
  methods: {
    getStatus(product) {
      if (product.item.is_unlimited) {
        return 1
      }

      return new Date(product.item.expired_date) - new Date() >= 0 ? 1 : 0
    }
  },
};
</script>
<style scoped>
.c-green {
  color: green;
}
.c-red {
  color: red;
}
</style>