<template>
  <div>
    <div class="my-3">
      <h2 class="h2">User learn progress</h2>
      <b-table-simple caption-top responsive bordered class="pt-2">
        <b-thead head-variant="light">
          <b-tr>
            <b-th class="text-center">
              Section
            </b-th>
            <b-th class="text-center">
              Total Learned Lesson
            </b-th>
            <b-th class="text-center">
              Current
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
              <b-td>Play (Arena Map)</b-td>
              <b-td>{{ data.learn_data.stage_played.count }}</b-td>
              <b-td>{{ data.learn_data.stage ? data.learn_data.stage.stage : '' }}</b-td>
          </b-tr>
          <b-tr>
              <b-td>Breaking</b-td>
              <b-td>{{ data.learn_data.breaking.total_lesson }}</b-td>
              <b-td>Lesson {{ data.learn_data.breaking.current_lesson_number }}: {{ data.learn_data.breaking.current_lesson_name }}</b-td>
          </b-tr>
          <b-tr>
              <b-td>Pronun</b-td>
              <b-td>{{ data.learn_data.pronun.total_lesson }}</b-td>
              <b-td>Lesson {{ data.learn_data.pronun.current_lesson_number }}: {{ data.learn_data.pronun.current_lesson_name }}</b-td>
          </b-tr>
          <b-tr v-for="item in data.learn_data.classic_speaking" :key="item._id">
              <b-td>Classic - Speaking - {{ item.category_key }}</b-td>
              <b-td>{{ item.progress_mark + 1 }}</b-td>
              <b-td>{{ item.lesson_title }}</b-td>
          </b-tr>
          <b-tr>
              <b-td>Classic - Listening</b-td>
              <b-td>{{ data.learn_data.classic_listening.progress_mark }}</b-td>
              <b-td>{{ data.learn_data.classic_listening.current_level }}</b-td>
          </b-tr>
          <b-tr>
              <b-td>Classic - Vocab</b-td>
              <b-td>{{ data.learn_data.classic_vocab.progress_mark }}</b-td>
              <b-td>Topic {{ data.learn_data.classic_vocab_current.current_level }}: {{ data.learn_data.classic_vocab_current.current_title }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <div class="my-3" v-if="data.pronun.length">
		<h2 class="h2">Pronun Result</h2>
		<b-table-simple caption-top responsive bordered class="pt-2">
        <b-thead head-variant="light">
          <b-tr>
            <b-th class="text-center">
              Tên lesson
            </b-th>
            <b-th class="text-center">
              Avg. Score
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="item in data.pronun" :key="item._id">
              <b-td>Lesson {{ item.lesson_level }}: {{ item.lesson_title }}</b-td>
              <b-td>{{ item.score }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
	</div>
  </div>
</template>
<script>
export default {
    props: {
        data: {type: Object, required: true}
    }
}
</script>