import api from '@/libs/axios'

export default {
  async getList(params) {
    let response = await api.get('/admin/configs', { params })
    return response
  },
  async get(params) {
    let response = await api.get('/admin/configs/detail', { params })
    return response.data.data.document || null
  },
  async create(payload) {
    const response = await api.post('/admin/configs', payload)
    return response.data.data.new_document || null
  },
  async update(payload) {
    let response = await api.put('/admin/configs', payload)
    return response
  },
  async delete(payload) {
    const response = await api.delete('/admin/configs', {
      data: payload,
    })
    return response
  },
  async getByKey(params, { default_data = null, category = 'system' }) {
    if (!params || !params.key) {
      return
    }

    let response = await this.get(params)
    if (!response) {
      response = await this.create({
        key: params.key,
        category: category,
        type: typeof default_data,
        value: default_data,
      })
    }

    return response
  },
}
