<template>
  <div v-if="display_results">
    <section class="py-3">
      <b-table :items="formatResult(display_results)" :fields="fields" responsive="sm">
        <template #cell(stt)="data">
          {{ data.index + 1 }}
        </template>
      </b-table>
    </section>
    <b-pagination
      class="mt-auto"
      pills
      size="lg"
      align="center"
      v-model="current_page"
      :total-rows="total_items"
      :per-page="items_perpage"
    />
  </div>
</template>
<script>
export default {
  props:{
    display_results: {type: Array, default: null}
  },
  data() {
    return {
      current_page: 1,
      total_items: 0,
      items_perpage: 30,
      fields: ["day","arena_learn", "arena_challenge", "vocab_topic", "pronun_topic", "listening_topic", "total"],
      is_recalculating: false,
    };
  },
  watch:{
    current_page() {
      this.$emit("changePage")
    },
  },
  methods:{
    formatResult(results) {
      return results.map((item) => {
        return {
          day: new Date(item.time.year, 0, item.time.year_day).toLocaleDateString('en-GB'),
          arena_learn: item.acts.arena_learn,
          arena_challenge: item.acts.arena_challenge,
          vocab_topic: item.acts.vocab_topic,
          pronun_topic: item.acts.pronun_topic,
          listening_topic: item.acts.listening_arena_topic,
          total: item.total,
        }
      })
    },
  }
}
</script>